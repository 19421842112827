import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import PageLayout from "../components/PageLayout";
import * as styles from "./css/about-us.module.scss";

const AboutUs = () => {
  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 700) {
        setMobile(true);
      } else setMobile(false);
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, [mobile]);

  return (
    <PageLayout>
      <title>Mobile Phone Service Provider | PhoneBox</title>
      <div className="container">
        <div className={styles.wrapper}>
          <div className={styles.pageTitle}>
            <Trans>About Us</Trans>
          </div>
          <div className={styles.firstSection}>
            <div className={styles.firstSectionContent}>
              <div className={styles.contextSection}>
                <div className={styles.sectionTitle}>
                  <Trans>Who we are</Trans>
                </div>
                <div className={styles.sectionText}>
                  <Trans>
                    We are passionate about providing the best possible customer
                    experience by offering affordable mobile plans. Our journey
                    has always been focused on one important goal: Offering the
                    most affordable and best-quality wireless plans to our
                    clients all across the globe.
                  </Trans>
                  <br /> <br />
                  {mobile && (
                    <div className={styles.firstSectionImage}>
                      <img src="/images/AboutUs1.png" />
                    </div>
                  )}
                  <Trans>
                    With Phonebox, you can acquire assistance from an
                    experienced partner who will provide technical support at
                    every turn. This also includes technical guidance from your
                    personal Account Executive expert, who will answer your
                    queries directly. We provide 4G LTE/5G mobile plans backed
                    by the power of the networks of Rogers, Telus Networks, and
                    T-Mobile in the United States. We help people use affordable
                    mobile services daily in every province and territory of
                    Canada and all 50 states of the United States.
                  </Trans>
                  <br /> <br />
                  <Trans>
                    We pride ourselves on the ability to make things simple. And
                    that is the reason why several institutions and
                    organizations that help international students and travelers
                    have partnered with us. We can ease the mobile transition so
                    people can stay in touch with their loved ones wherever they
                    may be.
                  </Trans>
                  <br /> <br />
                  <Trans
                    defaults="We are delighted to offer our multilingual customer
                    services. With our well-trained and efficient support team,
                    you will be able to communicate in your own language and get
                    your problem resolved within a matter of minutes! Simply
                    contact us at <b>855-886-0505</b>, and we will sort out your
                    issue quickly."
                    components={{
                      b: <b/>
                    }}
                  />
                </div>
              </div>
            </div>
            {!mobile && (
              <div className={styles.firstSectionImage}>
                <img src="/images/AboutUs1.png" />
              </div>
            )}
          </div>
          <div className={styles.secondSection + " " + styles.topMargin}>
            <div className={styles.secondSectionContent}>
              <div className={styles.contextSection}>
                <div className={styles.sectionTitle2}>
                  <Trans>No contracts, No commitments! All Freedom!</Trans>
                </div>
              </div>
            </div>
            <div className={styles.secondSectionImages}>
              <img src="/images/blueMan1.png" />
              <img src="/images/blueMan2.png" />
              <img src="/images/blueMan3.png" />
              <img src="/images/blueMan4.png" />
            </div>
          </div>
          <div className={styles.thirdSection + " " + styles.topMargin}>
            <div className={styles.thirdSectionImage}>
              <img src="/images/AboutUs2.png" />
            </div>
            <div className={styles.thirdSectionContent}>
              <div className={styles.contextSection}>
                <div className={styles.sectionTitle}>
                  <Trans>Why PhoneBox</Trans>
                </div>
                <ul style={{ marginTop: "10px", paddingLeft: "1.4rem" }}>
                  <li>
                    <Trans>
                      Offering the latest eSIM technology which allows you to
                      receive their SIM Card in an instant under a form of QR
                      code. No more waiting for the SIM Card to arrive via your
                      mailbox, and no more delays.
                    </Trans>
                  </li>
                  <li>
                    <Trans>
                      Scheduled activation allows you to schedule your service
                      starting date and have your SIM Card ready upon your
                      arrival. You are also able to contact our customer support
                      team to change your service date for any case of delay of
                      your arrival date.
                    </Trans>
                  </li>
                  <li>
                    <Trans>
                      At PhoneBox, we offer the Best Network at Lower Price,
                      along with a High-Speed 4G-LTE, 5G Data. This allows you
                      to take your internet browsing experience to the next
                      level.
                    </Trans>
                  </li>
                  <li>
                    <Trans>
                      We are offering Multi-Lingual Customer Service. With our
                      amazing support team, you are able to speak to one of our
                      representatives in your- language as we offer varieties of
                      different language support. Simply reach out to us at
                      855-886-0505 and one of our support teams will be happy to
                      assist you.
                    </Trans>
                  </li>
                  <li>
                    <Trans>
                      No Contracts, No Commitments. Even Though we are sad to
                      see you leave; however, at PhoneBox, there’s no contract
                      required for signing up for our service.
                    </Trans>
                  </li>
                  <li>
                    <Trans>
                      We save the best for the last. At PhoneBox, we offer a
                      24/7 Online Self-Service which allows you to simply log
                      into your online account and process any changes under
                      your account
                    </Trans>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
export default AboutUs;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
